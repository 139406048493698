































































































































































































































































































import { getEndorsementImageURL } from "./../../services/storage";
import Vue, { PropType } from "vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { TokenState, LicenseType } from "../../store/tokens";
import Multiselect from "vue-multiselect";

type TokenEditData = {
  error?: Error;
  message: boolean;
  dialog: boolean;
  Code: string;
  selectedOrganisation: any | null;
  selectedLicenseType?: LicenseType;
  formData: {
    token_id?: string;
    code?: string;
    description?: string;
    test_taker_reference_prompt?: string;
    organisation_id?: string;
    percentage_discount?: number;
    valid_from?: Date;
    valid_to?: Date;
    endorsements_template?: File;
    assessment_skills?: string[];
    quantity?: number;
    hybrid_assessment?: boolean;
    kyc_required?: boolean;
    invite_only?: boolean;
    domain?: string;
    per_user?: number;
    license_type?: string;
  };
  datepickerFromOpen: boolean;
  datepickerToOpen: boolean;
  datepickerDefaultFromDate: Date;
  datepickerDefaultToDate: Date;
  datepickerFormat: string;
  assessmentSkills: string[];
  shortCodes: string[];
  endorsementImageURL?: string;
  writingAIConfig: {
    calculate_ai_score: boolean;
    skip_grading_others: boolean;
    skip_peer_grading: boolean;
    use_ai_score: boolean;
  };
  speakingAIConfig: {
    calculate_ai_score: boolean;
    skip_grading_others: boolean;
    skip_peer_grading: boolean;
    use_ai_score: boolean;
  };
};

type TokenEditComputed = {
  busy: boolean;
  organisations: any[];
  items: any[];
  tokenDetails: any;
  withDiscount: boolean;
  isConnectCode: boolean;
  isCertificateCredit: boolean;
  withEndorsementsImage: boolean;
  withTestTakerReferencePrompt: boolean;
  submitDisabled: boolean;
  organisation: string;
  licenseTypes: LicenseType[];
  licenseTypeAllowedToSelect: LicenseType[];
  allowedSkills: string[];
  canUpgrade: boolean;
};

type TokenEditMethods = {
  setOrganisations: (organisations: any[]) => void;
  editToken: (payload: any) => void;
  loadOrganisations: () => void;
  loadLicenseTypes: () => void;
  datepickerFromDisabledDate: (date: Date) => boolean;
  datepickerFromDisabledTime: (time: Date) => boolean;
  datepickerToDisabledDate: (date: Date) => boolean;
  datepickerToDisabledTime: (time: Date) => boolean;
  close: () => void;
  hackTheDatepickerOkButton: () => void;
  onEndorsementsImageChange: (e: Event) => Promise<void>;
  onSubmit: () => boolean;
  loadTokenData: () => void;
  setTokenState: (tokenState: TokenState) => void;
  clearTokenState: () => void;
  loadEndorsementImage: (filename: string) => Promise<void>;
};

type TokenEditProps = {
  tokenState: TokenState;
};

export default Vue.extend<
  TokenEditData,
  TokenEditMethods,
  TokenEditComputed,
  TokenEditProps
>({
  data: () => ({
    error: undefined,
    message: false,
    dialog: false,
    Code: "",
    selectedOrganisation: null,
    selectedLicenseType: undefined,
    formData: {},
    datepickerFromOpen: false,
    datepickerToOpen: false,
    datepickerDefaultFromDate: new Date(new Date().setSeconds(0)),
    datepickerDefaultToDate: new Date(new Date().setHours(23, 59, 59)),
    datepickerFormat: "YYYY-MM-DD HH:mm Z",
    assessmentSkills: [],
    writingAIConfig: {
      calculate_ai_score: false,
      skip_grading_others: false,
      skip_peer_grading: false,
      use_ai_score: false
    },
    speakingAIConfig: {
      calculate_ai_score: false,
      skip_grading_others: false,
      skip_peer_grading: false,
      use_ai_score: false
    },
    shortCodes: [
      "00-AU",
      "UP-50",
      "H4B8D",
      "UOS4",
      "ANSHB",
      "UOS2",
      "ES247",
      "US-50",
      "TTINA",
      "IS-50",
      "00-UI",
      "PCORE",
      "KE-22",
      "ZS-24",
      "PAY25",
      "UOS3",
      "30OFF",
      "UOS1",
      "00-PU",
      "PTEST",
      "UC-50",
      "CUE1",
      "LP377",
      "CUE2",
      "00-SB"
    ],
    endorsementImageURL: undefined
  }),
  props: {
    tokenState: {
      type: String as PropType<TokenState>,
      required: true
    }
  },
  components: {
    Multiselect
  },
  computed: {
    ...mapGetters("tokens", [
      "busy",
      "organisations",
      "items",
      "tokenDetails",
      "licenseTypes"
    ]),
    withDiscount() {
      return this.tokenState === TokenState.CertificateVoucher;
    },
    isConnectCode() {
      return this.tokenState === TokenState.ConnectCode;
    },
    isCertificateCredit() {
      return this.tokenState === TokenState.CertificateCredit;
    },
    withEndorsementsImage() {
      return false; //  due to bucket access policy can't access image directly
      // return [
      //   TokenState.CertificateVoucher,
      //   TokenState.CertificateCredit
      // ].includes(this.tokenState);
    },
    withTestTakerReferencePrompt() {
      return this.tokenState === TokenState.ConnectCode;
    },
    submitDisabled() {
      return (
        this.busy ||
        !this.assessmentSkills.length ||
        !this.selectedOrganisation ||
        (this.isConnectCode && !this.selectedLicenseType)
      );
    },
    organisation() {
      return this.selectedOrganisation
        ? `${this.selectedOrganisation.name} (${this.selectedOrganisation?.hubspot_id})`
        : ``;
    },
    licenseTypeAllowedToSelect() {
      if (!this.formData.license_type) {
        return this.licenseTypes.map(licenseType => ({
          ...licenseType,
          $isDisabled: false
        }));
      }

      const _selectedLicenseType = this.licenseTypes.find(
        licenseType => licenseType.license_type === this.formData.license_type
      );
      return this.licenseTypes.map(licenseType => {
        if (licenseType.license_type === this.formData.license_type) {
          return {
            ...licenseType,
            $isDisabled: false
          };
        }
        return {
          ...licenseType,
          $isDisabled:
            !_selectedLicenseType?.upgrades_to.includes(
              licenseType.license_type
            ) ?? true
        };
      });
    },
    allowedSkills() {
      return this.selectedLicenseType?.skills || [];
    },
    canUpgrade() {
      return this.formData.license_type
        ? this.licenseTypes.some(
          licenseType =>
            licenseType.license_type === this.formData.license_type &&
            licenseType.upgrades_to.length > 0
        )
        : true;
    }
  },
  methods: {
    ...mapMutations("tokens", ["setOrganisations"]),
    ...mapActions("tokens", [
      "editToken",
      "loadOrganisations",
      "loadLicenseTypes",
      "setTokenState",
      "clearTokenState"
    ]),
    datepickerFromDisabledDate: date =>
      date < new Date(Date.now() - 3600 * 1000 * 23 * 2), // Two days ago
    datepickerFromDisabledTime: time =>
      time < new Date(Date.now() - 3600 * 1000 * 24 - 1000 * 60), // One day, One minute ago
    datepickerToDisabledDate: function (date) {
      if (!this.formData.valid_from)
        return this.datepickerFromDisabledDate(date);
      return (
        date < new Date(this.formData.valid_from.getTime() - 3600 * 1000 * 23)
      );
    },
    datepickerToDisabledTime: function (time) {
      if (!this.formData.valid_from)
        return this.datepickerFromDisabledTime(
          new Date(time.getTime() - 1000 * 60)
        ); // One minute ago
      return time < new Date(this.formData.valid_from.getTime() + 1000 * 60); // One minute after valid_from
    },
    close() {
      this.dialog = false;
    },
    /**
     * @hack For unknown reasons, the Datepicker's "OK" button mousedown event refocuses the form to the first input field, which is not what we want.
     * Solution: Disable the mousedown event on the Datepicker's "OK" button.
     */
    hackTheDatepickerOkButton() {
      this.$nextTick(() => {
        const datepickerConfirmButton = document.querySelector(
          ".mx-datepicker-btn-confirm"
        );
        datepickerConfirmButton?.addEventListener("mousedown", event => {
          event.preventDefault();
        });
      });
    },
    async onEndorsementsImageChange(e) {
      const { valid } = await (this.$refs
        .endorsementsImageProvider as any).validate(e);

      if (valid) {
        if ((this.$refs.endorsementsImage as any).files.length > 0) {
          this.formData.endorsements_template = (this.$refs
            .endorsementsImage as any).files[0];
        } else {
          this.formData.endorsements_template = undefined;
        }
      }
    },
    onSubmit() {
      const payload = { ...this.formData } as any;
      if (this.selectedOrganisation?.organisation_id) {
        payload.organisation_id = this.selectedOrganisation.organisation_id;
      }
      if (this.assessmentSkills.length > 0) {
        payload.assessment_skills = this.assessmentSkills;
      }

      if (this.selectedLicenseType) {
        if (
          this.formData.license_type != this.selectedLicenseType.license_type
        ) {
          if (!confirm("Are you sure you want to change the license type?")) {
            return false;
          }
        }
        payload.license_type = this.selectedLicenseType.license_type;
      }
      if (this.assessmentSkills.includes("writing") && this.isConnectCode) {
        payload.ai_configurations = {
          writing: this.writingAIConfig
        };
      }
      if (this.assessmentSkills.includes("spoken") && this.isConnectCode) {
        payload.ai_configurations = {
          ...(payload.ai_configurations ?? {}),
          spoken: this.speakingAIConfig
        };
      }
      this.editToken(payload);
      this.close();
      return false;
    },
    async loadEndorsementImage(filename) {
      try {
        this.endorsementImageURL = await getEndorsementImageURL(filename);
      } catch (err) {
        console.error(err);
        this.endorsementImageURL = undefined;
      }
    },
    loadTokenData() {
      const token = this.items.find(
        voucher => voucher.voucher_id == this.$route.params.uuid
      );
      if (!token) {
        this.$router.push({ name: "tokens" });
        return;
      }
      this.formData.token_id = token.voucher_id;
      this.formData.code = token.code;
      this.formData.description = token.description;
      this.formData.test_taker_reference_prompt =
        token.test_taker_reference_prompt;
      this.selectedOrganisation = this.organisations.find(
        organisation => organisation.organisation_id == token.organisation_id
      );
      this.formData.license_type = token.license_type;
      this.selectedLicenseType = this.licenseTypes.find(
        licenseType => licenseType.license_type == token.license_type
      );
      this.formData.percentage_discount = token.percentage_discount;
      this.formData.valid_from = new Date(Date.parse(token.valid_from));
      this.formData.valid_to = token.valid_to
        ? new Date(Date.parse(token.valid_to))
        : undefined;
      setTimeout(() => {
        this.assessmentSkills =
          token.assessment_skills
            ?.toLowerCase()
            .replace("core", "core_v1")
            .replace("speaking", "spoken")
            .split(", ") ?? [];
      }, 100);
      this.formData.quantity = token.quotas.quantity;
      this.formData.hybrid_assessment = token.hybrid_assessment;
      // this.formData.kyc_required = token.kyc_required;
      // this.formData.invite_only = token.invite_only;
      this.formData = {
        ...this.formData,
        kyc_required: token.kyc_required,
        invite_only: token.invite_only
      };
      this.formData.domain = token.quotas?.domain;
      this.formData.per_user = token.quotas?.per_user;
      this.writingAIConfig = token.ai_configurations?.writing ?? {
        calculate_ai_score: false,
        skip_grading_others: false,
        skip_peer_grading: false,
        use_ai_score: false
      };
      this.speakingAIConfig = token.ai_configurations?.spoken ?? {
        calculate_ai_score: false,
        skip_grading_others: false,
        skip_peer_grading: false,
        use_ai_score: false
      };
      if (token.endorsements_template_filename) {
        // failed to load image
        // this.loadEndorsementImage(token.endorsements_template_filename);
      }
    }
  },
  watch: {
    selectedLicenseType() {
      // on change of license type, check if the selected license type has skills
      // if it has skills, then set the assessmentSkills to the skills of the selected license type
      if (!this.canUpgrade) return;

      if (this.selectedLicenseType?.skills) {
        this.assessmentSkills = this.selectedLicenseType.skills;
      } else {
        this.assessmentSkills = [];
      }
    },
    "formData.invite_only"() {
      if (!this.formData.invite_only && this.formData.kyc_required) {
        this.formData.kyc_required = false;
      }
    },
    "formData.valid_from"() {
      // If valid_from changes, and is after the valid_to date, unset the valid_to value
      const { valid_from, valid_to } = this.formData;
      if (!valid_from || !valid_to) return;
      if (valid_from > valid_to) {
        this.formData.valid_to = undefined;
      }
    },
    async dialog(showingForm) {
      if (showingForm && this.organisations) {
        if (this.isConnectCode) {
          await this.loadLicenseTypes();
        }
        this.loadTokenData();
      } else {
        this.setOrganisations([]);
        this.datepickerFromOpen = false;
        this.datepickerToOpen = false;
        this.selectedOrganisation = null;
        this.assessmentSkills = [];
        this.formData = {
          valid_from: undefined,
          valid_to: undefined
        };
        requestAnimationFrame(() => {
          (this.$refs.refEditor as any).reset();
          (this.$refs.form as any).reset();
          if (this.$refs.endorsementsImage)
            (this.$refs.endorsementsImage as any).value = null;
        });

        // Change URL to be just the base path for this token type
        if (this.$route.params.uuid) {
          const tokenSlug = this.$route.path.split("/")[1];
          this.$router.push(`/${tokenSlug}`);
        }
      }
    },
    assessmentSkills() {
      if (this.$refs.form)
        (this.$refs.form as any).setErrors({
          AssessmentTypes: this.assessmentSkills.length
            ? []
            : ["At least one assessment type is required."]
        });
      if (this.isConnectCode) {
        if (!this.assessmentSkills.includes("writing")) {
          this.writingAIConfig.calculate_ai_score = false;
          this.writingAIConfig.use_ai_score = false;
          this.writingAIConfig.skip_grading_others = false;
        }
        if (!this.assessmentSkills.includes("spoken")) {
          this.speakingAIConfig.calculate_ai_score = false;
          this.speakingAIConfig.use_ai_score = false;
          this.speakingAIConfig.skip_grading_others = false;
        }
      }
    },
    datepickerFromOpen(open) {
      if (open) {
        this.hackTheDatepickerOkButton();
        this.formData.valid_from = this.datepickerDefaultFromDate;
      }
    },
    datepickerToOpen(open) {
      if (open) this.hackTheDatepickerOkButton();
    },
    $route: {
      handler() {
        this.loadOrganisations();
        this.dialog = [
          "ConnectCodesEdit",
          "CertificateCreditsEdit",
          "CertificateVouchersEdit"
        ].includes(this.$route.name!);
      },
      immediate: true
    }
  }
});
