







































































































































































































































































































import Vue, { PropType } from "vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import { TokenState, LicenseType } from "../../store/tokens";
import { required } from "vee-validate/dist/rules";

type TokensFormProps = {
  tokenState: TokenState;
};

type TokensFormData = {
  error?: Error;
  message: boolean;
  dialog: boolean;
  Code: string;
  selectedOrganisation: any;
  selectedLicenseType?: LicenseType;
  formData: {
    percentage_discount?: number;
    valid_from?: Date;
    valid_to?: Date;
    code?: string;
    hybrid_assessment?: boolean;
    kyc_required?: boolean;
    invite_only?: boolean;
    description?: string;
    test_taker_reference_prompt?: string;
    endorsements_template?: File | null;
    assessment_skills?: string[];
    quantity?: number;
    per_user?: number;
    domain?: string;
    license_type?: string;
  };
  datepickerFromOpen: boolean;
  datepickerToOpen: boolean;
  datepickerDefaultFromDate: Date;
  datepickerDefaultToDate: Date;
  datepickerFormat: string;
  assessmentSkills: string[];
  shortCodes: string[];
  writingAIConfig: {
    calculate_ai_score: boolean;
    skip_grading_others: boolean;
    skip_peer_grading: boolean;
    use_ai_score: boolean;
  };
  speakingAIConfig: {
    calculate_ai_score: boolean;
    skip_grading_others: boolean;
    skip_peer_grading: boolean;
    use_ai_score: boolean;
  };
};

type TokensFormComputed = {
  busy: boolean;
  tokenName: string;
  tokenLabel: string;
  organisations: any[];
  items: any[];
  withDiscount: boolean;
  isConnectCode: boolean;
  isCertificateCredit: boolean;
  withEndorsementsImage: boolean;
  withTestTakerReferencePrompt: boolean;
  submitDisabled: boolean;
  tokenDetails: {
    tokenLabel: string;
    noDataText: string;
    loadingText: string;
  };
  allowedSkills: string[];
  licenseTypes: LicenseType[];
};

type TokensFormMethods = {
  close: () => void;
  hackTheDatepickerOkButton: () => void;
  onEndorsementsImageChange: (e: Event) => void;
  onSubmit: () => boolean;
  navigateToAddNew: () => void;
  navigateToAddNewOrganisation: () => void;
  datepickerFromDisabledDate: (date: Date) => boolean;
  datepickerFromDisabledTime: (time: Date) => boolean;
  datepickerToDisabledDate: (date: Date) => boolean;
  datepickerToDisabledTime: (time: Date) => boolean;
  setOrganisations: (organisations: any[]) => void;
  loadOrganisations: () => void;
  loadLicenseTypes: () => void;
  addToken: (token: any) => void;
  setTokenState: (tokenState: TokenState) => void;
  clearTokenState: () => void;
};

export default Vue.extend<
  TokensFormData,
  TokensFormMethods,
  TokensFormComputed,
  TokensFormProps
>({
  components: {
    Multiselect
  },
  props: {
    tokenState: {
      type: String as PropType<TokenState>,
      required: true
    }
  },
  data: () => ({
    error: undefined,
    message: false,
    dialog: false,
    Code: "",
    selectedOrganisation: null,
    selectedLicenseType: undefined,
    formData: {
      percentage_discount: 100,
      description: ""
    },
    datepickerFromOpen: false,
    datepickerToOpen: false,
    datepickerDefaultFromDate: new Date(new Date().setSeconds(0)),
    datepickerDefaultToDate: new Date(new Date().setHours(23, 59, 59)),
    datepickerFormat: "YYYY-MM-DD HH:mm Z",
    assessmentSkills: [],
    writingAIConfig: {
      calculate_ai_score: false,
      skip_grading_others: false,
      skip_peer_grading: false,
      use_ai_score: false
    },
    speakingAIConfig: {
      calculate_ai_score: false,
      skip_grading_others: false,
      skip_peer_grading: false,
      use_ai_score: false
    },
    shortCodes: [
      "00-AU",
      "UP-50",
      "H4B8D",
      "UOS4",
      "ANSHB",
      "UOS2",
      "ES247",
      "US-50",
      "TTINA",
      "IS-50",
      "00-UI",
      "PCORE",
      "KE-22",
      "ZS-24",
      "PAY25",
      "UOS3",
      "30OFF",
      "UOS1",
      "00-PU",
      "PTEST",
      "UC-50",
      "CUE1",
      "LP377",
      "CUE2",
      "00-SB"
    ]
  }),
  computed: {
    ...mapGetters("tokens", [
      "busy",
      "tokenName",
      "tokenLabel",
      "organisations",
      "items",
      "tokenDetails",
      "licenseTypes"
    ]),
    withDiscount: function () {
      return this.tokenState === TokenState.CertificateVoucher;
    },
    isConnectCode: function () {
      return this.tokenState === TokenState.ConnectCode;
    },
    isCertificateCredit: function () {
      return this.tokenState === TokenState.CertificateCredit;
    },
    withEndorsementsImage: function () {
      return [
        TokenState.CertificateVoucher,
        TokenState.CertificateCredit
      ].includes(this.tokenState);
    },
    withTestTakerReferencePrompt: function () {
      return this.tokenState === TokenState.ConnectCode;
    },
    // rules() {
    //   let rulesString = ''

    //   if(this.shortCodes.includes(this.formData.code)){
    //     const rule = "required|max:20|min:2"
    //     rulesString = rule
    //   } else {
    //     const rule = "required|max:20|min:6"
    //     rulesString = rule
    //   }

    //   return rulesString
    // },
    submitDisabled: function () {
      return (
        this.busy || !this.assessmentSkills.length || !this.selectedOrganisation
      );
    },
    allowedSkills() {
      return this.selectedLicenseType?.skills || [];
    }
  },
  methods: {
    ...mapMutations("tokens", ["setOrganisations"]),
    ...mapActions("tokens", [
      "addToken",
      "loadOrganisations",
      "loadLicenseTypes",
      "setTokenState",
      "clearTokenState"
    ]),
    datepickerFromDisabledDate: date =>
      date < new Date(Date.now() - 3600 * 1000 * 23), // One day ago
    datepickerFromDisabledTime: time => time < new Date(Date.now() - 1000 * 60), // One minute ago
    datepickerToDisabledDate: function (date) {
      if (!this.formData.valid_from)
        return this.datepickerFromDisabledDate(date);
      return (
        date < new Date(this.formData.valid_from.getTime() - 3600 * 1000 * 23)
      );
    },
    datepickerToDisabledTime: function (time) {
      if (!this.formData.valid_from)
        return this.datepickerFromDisabledTime(
          new Date(time.getTime() - 1000 * 60)
        ); // One minute ago
      return time < new Date(this.formData.valid_from.getTime() + 1000 * 60); // One minute after valid_from
    },
    navigateToAddNew: function () {
      // Change URL to be the base path for this token type + /new
      const tokenSlug = this.$route.path.split("/")[1];
      this.$router.push(`/${tokenSlug}/new`);
    },
    navigateToAddNewOrganisation: function () {
      this.$router.push("/organisations/new");
    },
    close() {
      this.dialog = false;
    },
    /**
     * @hack For unknown reasons, the Datepicker's "OK" button mousedown event refocuses the form to the first input field, which is not what we want.
     * Solution: Disable the mousedown event on the Datepicker's "OK" button.
     */
    hackTheDatepickerOkButton() {
      this.$nextTick(() => {
        const datepickerConfirmButton = document.querySelector(
          ".mx-datepicker-btn-confirm"
        );
        datepickerConfirmButton?.addEventListener("mousedown", event => {
          event.preventDefault();
        });
      });
    },
    async onEndorsementsImageChange(e) {
      const { valid } = await (this.$refs
        .endorsementsImageProvider as any).validate(e);

      if (valid) {
        if ((this.$refs.endorsementsImage as any).files.length > 0) {
          this.formData.endorsements_template = (this.$refs
            .endorsementsImage as any).files[0];
        } else {
          this.formData.endorsements_template = null;
        }
      }
    },
    onSubmit() {
      const payload: any = { ...this.formData };
      if (this.selectedOrganisation?.organisation_id) {
        payload.organisation_id = this.selectedOrganisation.organisation_id;
      }
      if (this.assessmentSkills.length > 0) {
        payload.assessment_skills = this.assessmentSkills;
      }
      if (this.selectedLicenseType) {
        payload.license_type = this.selectedLicenseType.license_type;
      }

      if (this.assessmentSkills.includes("writing") && this.isConnectCode) {
        payload.ai_configurations = {
          writing: this.writingAIConfig
        };
      }

      if (this.assessmentSkills.includes("spoken") && this.isConnectCode) {
        payload.ai_configurations = {
          ...(payload.ai_configurations ?? {}),
          spoken: this.speakingAIConfig
        };
      }
      this.addToken(payload);
      this.close();
      return false;
    }
  },
  watch: {
    "writingAIConfig.calculate_ai_score"() {
      if (!this.writingAIConfig.calculate_ai_score) {
        this.writingAIConfig.use_ai_score = false;
        this.writingAIConfig.skip_grading_others = false;
        this.writingAIConfig.skip_peer_grading = false;
      }
    },
    "speakingAIConfig.calculate_ai_score"() {
      if (!this.speakingAIConfig.calculate_ai_score) {
        this.speakingAIConfig.use_ai_score = false;
        this.speakingAIConfig.skip_grading_others = false;
        this.speakingAIConfig.skip_peer_grading = false;
      }
    },
    selectedLicenseType() {
      // on change of license type, check if the selected license type has skills
      // if it has skills, then set the assessmentSkills to the skills of the selected license type
      if (this.selectedLicenseType?.skills) {
        this.assessmentSkills = this.selectedLicenseType.skills;
      } else {
        this.assessmentSkills = [];
      }
    },
    "formData.invite_only"() {
      if (!this.formData.invite_only && this.formData.kyc_required) {
        this.formData.kyc_required = false;
      }
    },
    // 'formData.code'() {
    //   const codeFormfield = document.getElementById("code").parentElement.classList
    //   this.Code = ''
    //   // Replace any number of spaces or dashes with a single dash.
    //   if (this.formData.code?.length) this.formData.code = this.formData.code.replace(/[\s-]+/g, '-')
    //   // https://vee-validate.logaretm.com/v3/guide/interaction-and-ux.html
    //   // https://vee-validate.logaretm.com/v3/advanced/server-side-validation.html#setting-errors-manually
    //   // See if this.formData.code is in items
    //   const found = this.items.find(item => item.code === this.formData.code)
    //   if (found && this.formData.code !== "") {
    //     this.Code = 'This code is already in use'
    //     setTimeout(() => {
    //     codeFormfield.add("error")
    //   }, 1)
    //   } else if(!this.$refs.form.errors.Code.length) {
    //     codeFormfield.remove("error")
    //   }

    // },
    "formData.valid_from"() {
      // If valid_from changes, and is after the valid_to date, unset the valid_to value
      const { valid_from, valid_to } = this.formData;
      if (!valid_from || !valid_to) return;
      if (valid_from > valid_to) {
        this.formData.valid_to = undefined;
      }
    },
    dialog(showingForm) {
      if (showingForm) {
        this.loadOrganisations();
        if (this.isConnectCode) {
          this.selectedLicenseType = undefined;
          this.loadLicenseTypes();
        }
      } else {
        this.setOrganisations([]);
        this.datepickerFromOpen = false;
        this.datepickerToOpen = false;
        this.selectedOrganisation = null;
        this.assessmentSkills = [];
        this.formData = {
          percentage_discount: 100
        };
        requestAnimationFrame(() => {
          (this.$refs.refEditor as any).reset();
          (this.$refs.form as any).reset();
          if (this.$refs.endorsementsImage)
            (this.$refs.endorsementsImage as any).value = null;
        });

        // Change URL to be just the base path for this token type
        if (this.$route.params.new) {
          const tokenSlug = this.$route.path.split("/")[1];
          this.$router.push(`/${tokenSlug}`);
        }
      }
    },
    selectedOrganisation() {
      (this.$refs.form as any).setErrors({
        Organisation: this.selectedOrganisation
          ? []
          : ["You must select an Organisation."]
      });
    },
    assessmentSkills() {
      (this.$refs.form as any).setErrors({
        AssessmentTypes: this.assessmentSkills.length
          ? []
          : ["At least one assessment type is required."]
      });
      if (this.isConnectCode) {
        if (!this.assessmentSkills.includes("writing")) {
          this.writingAIConfig.calculate_ai_score = false;
          this.writingAIConfig.use_ai_score = false;
          this.writingAIConfig.skip_grading_others = false;
          this.writingAIConfig.skip_peer_grading = false;
        }
        if (!this.assessmentSkills.includes("spoken")) {
          this.speakingAIConfig.calculate_ai_score = false;
          this.speakingAIConfig.use_ai_score = false;
          this.speakingAIConfig.skip_grading_others = false;
          this.speakingAIConfig.skip_peer_grading = false;
        }
      }
    },
    datepickerFromOpen(open) {
      if (open) this.hackTheDatepickerOkButton();
    },
    datepickerToOpen(open) {
      if (open) this.hackTheDatepickerOkButton();
    },
    $route: {
      handler() {
        this.dialog = this.$route.params.new === "new";
      },
      immediate: true
    }
  },
  mounted() {
    this.setTokenState(this.tokenState);
  },
  beforeDestroy() {
    // this.clearTokenState();
  }
});
