import { apiClient } from "@/services/api"


// Helpers
const getUserKYCStatus = async (item) => {
  const { sitting_id, user_id } = item;
  const params = {
    user_id,
    sitting_id
  };

  const {
    data
  } = await apiClient.get("/v1.0/dataflow/user-kyc-status", { params });

  return data
};


// Module
const state = {
  busy: false,
  data: {}
}

const mutations = {
  SET_BUSY: (state, payload) => {
    state.busy = payload
  },
  SET_DATA: (state, payload) => {
    state.data = payload
  },
  RESET: (state) => {
    state.busy = false
    state.data = null
  }
}

const actions = {
  FETCH_KYC_STATUS: async ({ commit, dispatch, state }, item) => {
    try {
      commit("SET_BUSY", true)
      const response = await getUserKYCStatus({
        sitting_id: item.sitting_id,
        user_id: item.user_id
      })
      // Pending
      // const response = await getUserKYCStatus({
      //   sitting_id: '92861bc8-a669-4953-837d-60b768b3e33b',
      //   user_id: 'bf9d9170-f081-4380-9c01-7e94c9cb09fd'
      // })

      // Docs submited
      // const response = await getUserKYCStatus({
      //   sitting_id: '221c54a7-b655-4279-9f44-73d095e3605e',
      //   user_id: '244332e2-69e5-4a99-a9e0-a475b572a086'
      // })
      // const response = await getUserKYCStatus({
      //   sitting_id: '7bb317a9-a767-4afa-9d5e-7434cd1644bd',
      //   user_id: '4d1ef2ee-06ce-472e-925d-3718a122331c'
      // })
      // console.log(response)
      commit("SET_DATA", {
        ...state.data,
        [item.order_id]: response
      })
    } catch (error) {
      dispatch(
        "snackbar/snack",
        {
          mode: "error",
          message: `⚠️ Error: <strong class="px-4">Sorry, something went wrong when fetching the KYC status for the sitting.</strong>`
        },
        { root: true }
      )
    } finally {
      commit("SET_BUSY", false)
    }
  },
  RESET: ({ commit }) => {
    commit("RESET")
  }
}

const getters = {
  isBusy: state => state.busy,
  data: state => state.data,
  kycDetails: state => orderId => ((orderId in state.data) ? state.data[orderId] : null)
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}