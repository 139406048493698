import { apiClient } from "@/services/api";
import dateString from "@/plugins/filters/date-string";
import { ActionTree, GetterTree, MutationTree } from "vuex";

export enum TokenState {
  NONE = "None",
  ConnectCode = "ConnectCode",
  CertificateCredit = "CertificateCredit",
  CertificateVoucher = "CertificateVoucher"
}

const copyAsPerTokenState = (tokenState: TokenState) => {
  switch (tokenState) {
    case TokenState.ConnectCode:
      return {
        tokenLabel: "Connect Code",
        noDataText: "No connect codes found.",
        loadingText: "Loading connect codes ...",
        tokenState
      };
    case TokenState.CertificateCredit:
      return {
        tokenLabel: "Certificate Credit",
        noDataText: "No certificate credits found.",
        loadingText: "Loading certificate credits ...",
        tokenState
      };
    case TokenState.CertificateVoucher:
      return {
        tokenLabel: "Certificate Voucher",
        noDataText: "No certificate vouchers found.",
        loadingText: "Loading certificate vouchers ...",
        tokenState
      };
    default:
      return {
        tokenState: TokenState.NONE
      };
  }
};

export interface LicenseType {
  license_type_id: string;
  license_type_name: string;
  license_type: string;
  skills: string[];
  upgrades_to: string[];
}

interface TokenDataState {
  // UI
  busy: boolean;
  error: any;

  // Token
  tokenName: string;
  tokenLabel: string;

  // TokensTable
  items: any[];
  headers: any[];
  noDataText: string;
  loadingText: string;

  // TokensForm
  item: any;
  defaultItem: any;
  organisations: any[];

  // Voucher types
  voucherTypeId: any;

  tokenState: TokenState;

  //
  licenseTypes: LicenseType[];
}

const state: TokenDataState = {
  // UI
  busy: false,
  error: undefined,

  // Token: Can be Connect Code, Certificate Voucher or Certificate Credit.
  tokenName: "",
  tokenLabel: "",

  // TokensTable
  items: [],
  headers: [],
  noDataText: "",
  loadingText: "",

  // TokensForm
  item: {},
  defaultItem: {},
  organisations: [],

  // Voucher types
  voucherTypeId: {},

  tokenState: TokenState.NONE,

  // License types
  licenseTypes: []
};

const mutations: MutationTree<TokenDataState> = {
  // UI
  setBusy: (state, payload) => (state.busy = payload),
  setError: (state, payload) => (state.error = payload),

  // Token
  setTokenName: (state, payload) => (state.tokenName = payload),
  setTokenLabel: (state, payload) => (state.tokenLabel = payload),

  // TokensTable
  setItems: (state, payload) => (state.items = payload),
  setHeaders: (state, payload) => (state.headers = payload),
  setNoDataText: (state, payload) => (state.noDataText = payload),
  setLoadingText: (state, payload) => (state.loadingText = payload),

  // TokensForm
  setItem: (state, payload) => (state.item = payload),
  setDefaultItem: (state, payload) => (state.defaultItem = payload),
  setOrganisations: (state, payload) => (state.organisations = payload),

  // Voucher types
  setVoucherTypeId: (state, payload) => (state.voucherTypeId = payload),

  setTokenState: (state, payload) => (state.tokenState = payload),

  // License types
  setLicenseTypes: (state, payload) => (state.licenseTypes = payload)
};

const getters: GetterTree<TokenDataState, unknown> = {
  // UI
  busy: state => state.busy,
  error: state => state.error,

  // Token
  tokenName: state => state.tokenName,
  tokenLabel: state => state.tokenLabel,

  // TokensTable
  items: state => state.items,
  headers: state => state.headers,
  noDataText: state => state.noDataText,
  loadingText: state => state.loadingText,

  // TokensForm
  item: state => state.item,
  defaultItem: state => state.defaultItem,
  organisations: state => state.organisations,

  // Voucher types
  voucherTypeId: state => state.voucherTypeId,

  tokenDetails: state => copyAsPerTokenState(state.tokenState),

  // License types
  licenseTypes: state => state.licenseTypes
};

// const addToken = async ({ commit, getters, dispatch }, item) => {
//   commit("setBusy", true);

//   // Use multipart/form-data to allow image uploads
//   // @see https://github.com/EnglishScore/mohawk-backend/blob/main/englishscore/interfaces/api/v1_11/vouchers.py#L19
//   const formData = new FormData();
//   const acceptedProperties = [
//     "assessment_skills",
//     "code",
//     "description",
//     "endorsements_template",
//     "organisation_id",
//     "percentage_discount",
//     "quantity",
//     "test_taker_reference_prompt",
//     "hybrid_assessment",
//     "per_user",
//     "domain"
//   ];
//   acceptedProperties.forEach(property => {
//     if (item[property]) {
//       formData.append(
//         property,
//         typeof item[property] === "string"
//           ? item[property].trim()
//           : item[property]
//       );
//     }
//   });

//   // Assessment skills
//   // @see https://developer.mozilla.org/en-US/docs/Web/API/FormData/append#example
//   if (item.assessment_skills) {
//     formData.append("assessment_skills[]", item.assessment_skills);
//   }

//   // Format dates
//   if (item.valid_from) {
//     formData.append("valid_from", item.valid_from.toISOString());
//   }
//   if (item.valid_to) {
//     formData.append("valid_to", item.valid_to.toISOString());
//   }

//   /**
//    * How is voucher_type_id determined when adding a new token?
//    * @see loadItems() action where setVoucherTypeId() is called
//    */
//   const { tokenName, voucherTypeId } = getters;
//   if (["certificate-credit", "connect-code"].includes(tokenName)) {
//     formData.append("voucher_type_id", voucherTypeId);
//   }
//   if (tokenName === "certificate-voucher") {
//     const { free, relative_discount } = voucherTypeId;
//     formData.append(
//       "voucher_type_id",
//       item.percentage_discount === 100 ? free : relative_discount
//     );
//   }

//   // Quantity refers to how many times a token can be 'used'.
//   // Enforce-quantity is a flag to tell the back-end whether or not to actually restrict uses. Default: false.
//   // Set enforce_quantity to true for if `quantity` is set. -- Currently only available for certificate-credits.
//   if (item.quantity) {
//     formData.append("enforce_quantity", "true");
//   }

//   try {
//     await apiClient.post(`/v1.11/voucher`, formData, {
//       headers: {
//         "Content-Type": "multipart/form-data"
//       }
//     });
//     dispatch(
//       "snackbar/snack",
//       {
//         mode: "success",
//         message: `✅ Added ${getters.tokenLabel}: <strong class="px-4">${item.code}</strong>`
//       },
//       { root: true }
//     );
//   } catch (error) {
//     console.error(error);
//     // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//     // @ts-ignore:next-line
//     const { message } = error.response?.data || error;
//     dispatch(
//       "snackbar/snack",
//       {
//         mode: "error",
//         message: `⚠️ Error: <strong class="px-4">${message}</strong>`
//       },
//       { root: true }
//     );
//   } finally {
//     commit("setBusy", false);
//     dispatch("loadItems");
//   }
// };

// const editToken = async ({ commit, getters, dispatch }, item) => {
//   commit("setBusy", true);

//   const data = {
//     voucher_id: item.token_id,
//     "assessment_skills[]": item.assessment_skills.toString(),
//     valid_from: item.valid_from
//       ? item.valid_from.toISOString().replace("Z", "+00:00")
//       : null,
//     valid_to: item.valid_to
//       ? item.valid_to.toISOString().replace("Z", "+00:00")
//       : null,
//     hybrid_assessment: item.hybrid_assessment,
//     description: item.description,
//     test_taker_reference_prompt: item.test_taker_reference_prompt?.trim(),
//     per_user: Number(item.per_user) || undefined,
//     domain: item.domain?.trim()
//   };

//   try {
//     await apiClient.patch(`/v1.11/voucher`, data, {
//       headers: {
//         "Content-Type": "application/json"
//       }
//     });
//     dispatch(
//       "snackbar/snack",
//       {
//         mode: "success",
//         message: `✅ Updated ${getters.tokenLabel}: <strong class="px-4">${item.code}</strong>`
//       },
//       { root: true }
//     );
//   } catch (error) {
//     console.error(error);
//     // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//     // @ts-ignore:next-line
//     const { message } = error.response?.data || error;
//     dispatch(
//       "snackbar/snack",
//       {
//         mode: "error",
//         message: `⚠️ Error: <strong class="px-4">${message}</strong>`
//       },
//       { root: true }
//     );
//   } finally {
//     commit("setBusy", false);
//     dispatch("loadItems");
//   }
// };

// const removeToken = async ({ commit, getters, dispatch }, { code }) => {
//   commit("setBusy", true);
//   try {
//     await apiClient.delete(`/v1.11/voucher`, { data: { code } });
//     dispatch(
//       "snackbar/snack",
//       {
//         mode: "success",
//         message: `✅ Removed ${getters.tokenLabel}: <strong class="px-4">${code}</strong>`
//       },
//       { root: true }
//     );
//   } catch (error) {
//     console.error(error);
//     // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//     // @ts-ignore:next-line
//     const { message } = error.response?.data || error;
//     dispatch(
//       "snackbar/snack",
//       {
//         mode: "error",
//         message: `⚠️ Error: <strong class="px-4">${message}</strong>`
//       },
//       { root: true }
//     );
//   } finally {
//     commit("setBusy", false);
//     dispatch("loadItems");
//   }
// };

/**
 * @Note The `/v1.11/voucher` API is slightly unpredictable.
 *
 * There is no way to get a full list of all vouchers.
 *
 * This works as expected:
 *  If the `voucher_type_id` parameter is specified, the API will return only vouchers with that voucher_type_id.
 *
 * This is unexpected:
 *  If `voucher_type_id` is not specified, the API will automatically filter the results,
 *  and return only vouchers with a `product_type` of "certificate".
 *
 * As expected:
 *  For Voucher-type IDs with a `product_type` of "employee_sharing",
 *    the API response includes "Connect Codes" only.
 *
 *  For voucher-type IDs with a `product_name` of "certificate_credit",
 *    the API response includes "Certificate Credits" only.
 *
 * Unexpected:
 *  For voucher-type IDs with a `product_type` of "certificate",
 *    the API response includes "Certificate Vouchers" AND "Certificate Credits".
 *
 *  To get a list of "Certificate Vouchers" only, we filter the API response by `name`.
 */
const loadItems = async ({ commit, getters }) => {
  commit("setBusy", true);
  commit("setError", undefined);
  const { tokenDetails } = getters;

  // Certificate Vouchers have a discount column, but others do not;
  // and connect-codes have a test-taker-reference-prompt column;
  // so reset headers accordingly
  let headers = [
    { text: "Code", value: "code" },
    { text: "License Type", value: "license_type" },
    { text: "Valid from", value: "valid_from" },
    { text: "Expires on", value: "valid_to" },
    { text: "Organisation", value: "organisation_name" },
    { text: "Description", value: "description" },
    { text: "Assessment skills", value: "assessment_skills" },
    { text: "Quantity", value: "quotas.quantity" },
    { text: "Quantity enforced", value: "quotas.enforce_quantity" },
    { text: "Per user", value: "quotas.per_user" },
    { text: "Discount", value: "percentage_discount" },
    {
      text: "Reference prompt",
      value: "test_taker_reference_prompt"
    },
    {
      text: "Has endorsement template",
      value: "endorsements_template_filename",
      sortable: false
    },
    {
      text: "Domain",
      value: "quotas.domain",
      sortable: false
    },
    {
      text: "Hybrid assessment",
      value: "hybrid_assessment",
      sortable: false
    },
    {
      text: "KYC Required",
      value: "kyc_required",
      sortable: false
    },
    {
      text: "Invite Only",
      value: "invite_only",
      sortable: false
    },
    {
      text: "Calculate AI Score",
      value: "ai_configurations_calculate_ai_score",
      sortable: false
    },
    {
      text: "Skip grading others",
      value: "ai_configurations_skip_grading_others",
      sortable: false
    },
    {
      text: "Use AI Score",
      value: "ai_configurations_use_ai_score",
      sortable: false
    },
    { text: "", value: "actions", align: "right", sortable: false }
  ];
  if (tokenDetails.tokenState === TokenState.CertificateVoucher) {
    headers = headers.filter(
      ({ value }) =>
        [
          "quotas.per_user",
          "quotas.quantity",
          "quotas.enforce_quantity",
          "quotas.domain",
          "test_taker_reference_prompt",
          "hybrid_assessment",
          "kyc_required",
          "invite_only",
          "license_type",
          "ai_configurations_writing_calculate_ai_score",
          "ai_configurations_writing_use_ai_score",
          "ai_configurations_writing_skip_grading_others"
        ].includes(value) == false
    );
  }
  if (tokenDetails.tokenState === TokenState.ConnectCode) {
    headers = headers.filter(
      ({ value }) =>
        [
          "quotas.quantity",
          "quotas.enforce_quantity",
          "endorsements_template_filename",
          "percentage_discount"
        ].includes(value) === false
    );
  }
  if (tokenDetails.tokenState === TokenState.CertificateCredit) {
    headers = headers.filter(
      ({ value }) =>
        [
          "quotas.per_user",
          "quotas.domain",
          "percentage_discount",
          "test_taker_reference_prompt",
          "hybrid_assessment",
          "kyc_required",
          "invite_only",
          "license_type",
          "ai_configurations_writing_calculate_ai_score",
          "ai_configurations_writing_use_ai_score",
          "ai_configurations_writing_skip_grading_others"
        ].includes(value) === false
    );
  }
  commit("setHeaders", headers);

  try {
    // Load voucher types for immediate use when loading TokensTable data; store for later use in the TokensForm.
    const {
      data: { voucher_types }
    } = await apiClient.get(`/v1.11/voucher/types`);

    let voucher_type_id;
    if (
      [TokenState.CertificateCredit, TokenState.ConnectCode].includes(
        tokenDetails.tokenState
      )
    ) {
      // If the token is 'certificate-credit', the voucher_type is the one with a name of "certificate_credit".
      // If the token is 'connect-code', the voucher_type is the one with a product_type of "employee_sharing".
      const finderFunctions: Record<TokenState, (item) => boolean> = {
        [TokenState.CertificateCredit]: ({ name }) =>
          name === "certificate_credit",
        [TokenState.ConnectCode]: ({ product_type }) =>
          product_type === "employee_sharing",
        [TokenState.CertificateVoucher]: () => false,
        [TokenState.NONE]: () => false
      };
      voucher_type_id = voucher_types.find(
        finderFunctions[tokenDetails.tokenState]
      )?.voucher_type_id;
      commit("setVoucherTypeId", voucher_type_id);
    }

    if (tokenDetails.tokenState === TokenState.CertificateVoucher) {
      // If the token is 'certificate-voucher', there are two possible voucher_types:
      //   "free" and "relative_discount".
      //   Both have a product_type of "certificate".
      const free = voucher_types.find(
        ({ product_type, name }) =>
          product_type === "certificate" && name === "free"
      ).voucher_type_id;
      const relative_discount = voucher_types.find(
        ({ product_type, name }) =>
          product_type === "certificate" && name === "relative_discount"
      ).voucher_type_id;
      commit("setVoucherTypeId", {
        free,
        relative_discount
      });
    }

    // Fetch the TokenTable data from the API
    const {
      data: { results }
    } = await apiClient.get(`/v1.11/voucher`, {
      params: { voucher_type_id: voucher_type_id, skip_usage_stats: true }
    });

    // "Certificate vouchers": Filter out certificate-credits
    const filteredItems =
      tokenDetails.tokenState === TokenState.CertificateVoucher
        ? results.filter(
          ({ voucher_type }) => voucher_type !== "certificate_credit"
        )
        : results;

    // Parse the response into a format that the table component can use
    const parsedItems = filteredItems.reduce((accumulator, item) => {
      const {
        voucher_id,
        status,
        code,
        description,
        valid_from,
        valid_to,
        organisation_id,
        organisation_name,
        percentage_discount,
        test_taker_reference_prompt,
        quotas,
        hybrid_assessment,
        kyc_required,
        invite_only,
        endorsements_template_filename,
        license_type_name,
        license_type,
        ai_configurations
      } = item;

      // Map assessment_skills to human-friendly labels
      const skillLabels = {
        core_v1: "Core",
        spoken: "Speaking",
        writing: "Writing",
        written: "Writing"
      };
      const assessment_skills = quotas?.assessment_skills
        ?.map(skill => skillLabels[skill])
        ?.sort()
        .join(", ");

      accumulator.push({
        voucher_id,
        status,
        code,
        valid_from,
        valid_to,
        organisation_id,
        organisation_name,
        formatted_valid_from: dateString(valid_from),
        formatted_valid_to: dateString(valid_to),
        description,
        assessment_skills,
        percentage_discount,
        test_taker_reference_prompt,
        quotas,
        hybrid_assessment,
        kyc_required,
        invite_only,
        endorsements_template_filename,
        license_type_name: license_type_name,
        license_type: license_type,
        ai_configurations
      });
      return accumulator;
    }, []);
    commit("setItems", parsedItems);
  } catch (error) {
    commit("setError", error);
  } finally {
    commit("setBusy", false);
  }
};

// Load the list of organisations for src/components/tokens/TokensForm.vue
const loadOrganisations = async ({ commit, getters, dispatch }) => {
  /**
   * @note for hubspot_id
   * Don't expect "hubspot_id" to be available in dev or staging. Only the production environment will communicate with HubSpot.
   */
  try {
    const { data } = await apiClient.get(`/v1.11/organisations`);
    const organisations = data.results
      ?.sort((a, b) => a.name.localeCompare(b.name))
      .map(organisation => ({
        ...organisation,
        label: `${organisation.name} (${organisation.hubspot_id})`
      }));

    // "Certificate credits": Each organisation can only have one certificate credit.
    // Filter out organisations that already have valid certificate-credits, so they can't be selected by mistake.
    const { tokenName, items } = getters;
    const filteredOrganisations =
      tokenName === "certificate-credit"
        ? organisations.filter(
          ({ organisation_id }) =>
            !items.some(
              ({ status, organisation_id: id }) =>
                status === "valid" && id === organisation_id
            )
        )
        : organisations;

    commit("setOrganisations", filteredOrganisations);
  } catch (error) {
    console.error(error);
    dispatch(
      "snackbar/snack",
      {
        mode: "error",
        message: `⚠️ Error: <strong class="px-4">Organisations did not load correctly.</strong>`
      },
      { root: true }
    );
  }
};

const actions: ActionTree<TokenDataState, unknown> = {
  addToken: async ({ commit, dispatch, getters }, item) => {
    commit("setBusy", true);

    // Use multipart/form-data to allow image uploads
    // @see https://github.com/EnglishScore/mohawk-backend/blob/main/englishscore/interfaces/api/v1_11/vouchers.py#L19
    const formData = new FormData();
    const acceptedProperties = [
      "assessment_skills",
      "code",
      "description",
      "organisation_id"
    ];
    acceptedProperties.forEach(property => {
      if (item[property]) {
        formData.append(
          property,
          typeof item[property] === "string"
            ? item[property].trim()
            : item[property]
        );
      }
    });

    // Assessment skills
    // @see https://developer.mozilla.org/en-US/docs/Web/API/FormData/append#example
    if (item.assessment_skills) {
      formData.append("assessment_skills[]", item.assessment_skills);
    }

    // Format dates
    if (item.valid_from) {
      formData.append("valid_from", item.valid_from.toISOString());
    }
    if (item.valid_to) {
      formData.append("valid_to", item.valid_to.toISOString());
    }

    /**
     * How is voucher_type_id determined when adding a new token?
     * @see loadItems() action where setVoucherTypeId() is called
     */
    const { voucherTypeId, tokenDetails } = getters;

    if (tokenDetails.tokenState === TokenState.CertificateVoucher) {
      const percentage_discount = item.percentage_discount
        ? Number(item.percentage_discount)
        : null;
      if (percentage_discount !== null) {
        formData.append("percentage_discount", `${percentage_discount}`);
      }

      const { free, relative_discount } = voucherTypeId;
      formData.append(
        "voucher_type_id",
        percentage_discount === 100 ? free : relative_discount
      );

      const endorsements_template = item.endorsements_template ?? null;
      if (endorsements_template) {
        formData.append("endorsements_template", endorsements_template);
      }
    }

    if (tokenDetails.tokenState === TokenState.CertificateCredit) {
      formData.append("voucher_type_id", voucherTypeId);
      const quantity = item.quantity ? Number(item.quantity) : null;
      if (quantity != null) {
        formData.append("quantity", item.quantity);

        // Quantity refers to how many times a token can be 'used'.
        // Enforce-quantity is a flag to tell the back-end whether or not to actually restrict uses. Default: false.
        // Set enforce_quantity to true for if `quantity` is set. -- Currently only available for certificate-credits.
        formData.append("enforce_quantity", item.quantity ? "true" : "false");
      }

      const endorsements_template = item.endorsements_template ?? null;
      if (endorsements_template) {
        formData.append("endorsements_template", endorsements_template);
      }
    }

    if (tokenDetails.tokenState === TokenState.ConnectCode) {
      formData.append("voucher_type_id", voucherTypeId);
      const test_taker_reference_prompt =
        item.test_taker_reference_prompt?.trim() ?? null;
      if (
        test_taker_reference_prompt &&
        test_taker_reference_prompt.length > 0
      ) {
        formData.append(
          "test_taker_reference_prompt",
          test_taker_reference_prompt
        );
      }
      formData.append("hybrid_assessment", item.hybrid_assessment ?? false);
      formData.append("kyc_required", item.kyc_required ?? false);
      formData.append("invite_only", item.invite_only ?? false);
      const per_user = Number(item.per_user) || null;
      if (per_user) {
        formData.append("per_user", `${per_user}`);
      }
      const domain = item.domain?.trim() ?? null;
      if (domain) {
        formData.append("domain", domain);
      }
      // attach license type to voucher
      formData.append("license_type", item.license_type);
      console.log(item, item?.ai_configurations)
      if (
        item.assessment_skills.includes("writing") &&
        item?.ai_configurations?.writing
      ) {
        formData.append(
          "ai_configurations[writing][calculate_ai_score]",
          item.ai_configurations.writing.calculate_ai_score
        );
        formData.append(
          "ai_configurations[writing][use_ai_score]",
          item.ai_configurations.writing.use_ai_score
        );
        formData.append(
          "ai_configurations[writing][skip_grading_others]",
          item.ai_configurations.writing.skip_grading_others
        );
      }

      if (
        item.assessment_skills.includes("spoken") &&
        item?.ai_configurations?.spoken
      ) {
        formData.append(
          "ai_configurations[spoken][calculate_ai_score]",
          item.ai_configurations.spoken.calculate_ai_score
        );
        formData.append(
          "ai_configurations[spoken][use_ai_score]",
          item.ai_configurations.spoken.use_ai_score
        );
        formData.append(
          "ai_configurations[spoken][skip_grading_others]",
          item.ai_configurations.spoken.skip_grading_others
        );
      }
    }

    try {
      await apiClient.post(`/v1.11/voucher`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      dispatch(
        "snackbar/snack",
        {
          mode: "success",
          message: `✅ Added ${tokenDetails.tokenLabel}: <strong class="px-4">${item.code}</strong>`
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore:next-line
      const { message } = error.response?.data || error;
      dispatch(
        "snackbar/snack",
        {
          mode: "error",
          message: `⚠️ Error: <strong class="px-4">${message}</strong>`
        },
        { root: true }
      );
    } finally {
      commit("setBusy", false);
      dispatch("loadItems");
    }
  },
  editToken: async ({ commit, getters, dispatch }, item) => {
    commit("setBusy", true);
    const { tokenDetails } = getters;
    const data: any = {
      voucher_id: item.token_id,
      "assessment_skills[]": item.assessment_skills.toString(),
      valid_from: item.valid_from
        ? item.valid_from.toISOString().replace("Z", "+00:00")
        : null,
      valid_to: item.valid_to
        ? item.valid_to.toISOString().replace("Z", "+00:00")
        : null,
      description: item.description
    };

    if (tokenDetails.tokenState === TokenState.CertificateVoucher) {
      const { voucherTypeId } = getters;
      const { free, relative_discount } = voucherTypeId;
      data.percentage_discount = item.percentage_discount
        ? Number(item.percentage_discount)
        : null;
      data.voucher_type_id =
        data.percentage_discount === 100 ? free : relative_discount;
    }

    if (tokenDetails.tokenState === TokenState.CertificateCredit) {
      data.quantity = Number(item.quantity) || null;
      data.enforce_quantity = data.quantity ? true : false;
      data.endorsements_template = item.endorsements_template ?? null;
    }

    if (tokenDetails.tokenState === TokenState.ConnectCode) {
      const test_taker_reference_prompt = item.test_taker_reference_prompt?.trim();
      data.test_taker_reference_prompt =
        test_taker_reference_prompt?.length > 0
          ? test_taker_reference_prompt
          : null;
      data.hybrid_assessment = item.hybrid_assessment;
      data.kyc_required = item.kyc_required;
      data.invite_only = item.invite_only;
      data.per_user = Number(item.per_user) || null;
      data.domain = item.domain?.trim() ?? null;
      // attach license type to voucher
      data.license_type = item.license_type;

      if (
        item.assessment_skills.includes("writing") &&
        item?.ai_configurations?.writing
      ) {
        data["ai_configurations[writing][calculate_ai_score]"] =
          item.ai_configurations.writing.calculate_ai_score;
        data["ai_configurations[writing][use_ai_score]"] =
          item.ai_configurations.writing.use_ai_score;
        data["ai_configurations[writing][skip_grading_others]"] =
          item.ai_configurations.writing.skip_grading_others;
      }
      if (
        item.assessment_skills.includes("spoken") &&
        item?.ai_configurations?.spoken
      ) {
        data["ai_configurations[spoken][calculate_ai_score]"] =
          item.ai_configurations.spoken.calculate_ai_score;
        data["ai_configurations[spoken][use_ai_score]"] =
          item.ai_configurations.spoken.use_ai_score;
        data["ai_configurations[spoken][skip_grading_others]"] =
          item.ai_configurations.spoken.skip_grading_others;
      }
    }

    try {
      await apiClient.patch(`/v1.11/voucher`, data, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      dispatch(
        "snackbar/snack",
        {
          mode: "success",
          message: `✅ Updated ${tokenDetails.tokenLabel}: <strong class="px-4">${item.code}</strong>`
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore:next-line
      const { message } = error.response?.data || error;
      dispatch(
        "snackbar/snack",
        {
          mode: "error",
          message: `⚠️ Error: <strong class="px-4">${message}</strong>`
        },
        { root: true }
      );
    } finally {
      commit("setBusy", false);
      dispatch("loadItems");
    }
  },
  removeToken: async ({ commit, getters, dispatch }, { code }) => {
    commit("setBusy", true);
    try {
      const { tokenDetails } = getters;
      await apiClient.delete(`/v1.11/voucher`, { data: { code } });
      dispatch(
        "snackbar/snack",
        {
          mode: "success",
          message: `✅ Removed ${tokenDetails.tokenLabel}: <strong class="px-4">${code}</strong>`
        },
        { root: true }
      );
    } catch (error) {
      console.error(error);
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore:next-line
      const { message } = error.response?.data || error;
      dispatch(
        "snackbar/snack",
        {
          mode: "error",
          message: `⚠️ Error: <strong class="px-4">${message}</strong>`
        },
        { root: true }
      );
    } finally {
      commit("setBusy", false);
      dispatch("loadItems");
    }
  },
  loadItems,
  loadOrganisations,
  loadLicenseTypes: async ({ commit }) => {
    try {
      const { data } = await apiClient.get(`/v1.11/voucher/license-types`);
      commit("setLicenseTypes", data);
    } catch (error) {
      console.error(error);
    }
  },
  setTokenState: ({ commit }, tokenState) =>
    commit("setTokenState", tokenState),
  clearTokenState: ({ commit }) => commit("setTokenState", TokenState.NONE)
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
